import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import TermsOfUse from './views/terms-of-use'
import Home from './views/home'
import TermsAndConditions from './views/terms-and-conditions'
import PrivacyPolicy from './views/privacy-policy'
import CookiePolicy from './views/cookie-policy'
import JoinNow from './views/join-now'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={TermsOfUse} exact path="/terms-of-use" />
        <Route component={Home} exact path="/" />
        <Route
          component={TermsAndConditions}
          exact
          path="/terms-and-conditions"
        />
        <Route component={PrivacyPolicy} exact path="/privacy-policy" />
        <Route component={CookiePolicy} exact path="/cookie-policy" />
        <Route component={JoinNow} exact path="/join-now" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
