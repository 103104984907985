import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Question12 from '../components/question12'
import './privacy-policy.css'

const PrivacyPolicy = (props) => {
  return (
    <div className="privacy-policy-container">
      <Helmet>
        <title>Privacy-Policy - Meet Women Online</title>
        <meta name="description" content="Meet Slavic Women" />
        <meta
          property="og:title"
          content="Privacy-Policy - Meet Women Online"
        />
        <meta property="og:description" content="Meet Slavic Women" />
      </Helmet>
      <div className="privacy-policy-header">
        <header
          data-thq="thq-navbar"
          className="navbarContainer privacy-policy-navbar-interactive"
        >
          <span className="logo">FRIENDS</span>
          <div
            data-thq="thq-navbar-nav"
            className="privacy-policy-desktop-menu"
          >
            <nav className="privacy-policy-links">
              <Link to="/terms-and-conditions" className="privacy-policy-nav12">
                <span className="bodySmall">Terms and Conditions</span>
                <br></br>
              </Link>
              <span className="privacy-policy-nav22">
                <span className="bodySmall">Privacy Policy</span>
                <br></br>
              </span>
              <span className="privacy-policy-nav32">
                <span className="bodySmall">Cookie Policy</span>
                <br></br>
              </span>
              <span className="privacy-policy-nav42 bodySmall">
                Terms of Use
              </span>
            </nav>
            <div className="privacy-policy-buttons">
              <button className="privacy-policy-login buttonFlat">Login</button>
              <button className="buttonFilled">Register</button>
            </div>
          </div>
          <div
            data-thq="thq-burger-menu"
            className="privacy-policy-burger-menu"
          >
            <svg
              viewBox="0 0 1024 1024"
              className="privacy-policy-icon socialIcons"
            >
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
            data-thq="thq-mobile-menu"
            className="privacy-policy-mobile-menu1 mobileMenu"
          >
            <div className="privacy-policy-nav">
              <div className="privacy-policy-top">
                <span className="logo">FRIENDS</span>
                <div
                  data-thq="thq-close-menu"
                  className="privacy-policy-close-menu"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="privacy-policy-icon02 socialIcons"
                  >
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="privacy-policy-links1">
                <span className="privacy-policy-nav121 bodySmall">About</span>
                <span className="privacy-policy-nav221 bodySmall">Events</span>
                <span className="privacy-policy-nav321 bodySmall">Blog</span>
                <span className="privacy-policy-nav421 bodySmall">Contact</span>
              </nav>
              <div className="privacy-policy-buttons1">
                <button className="buttonFlat">Login</button>
                <button className="buttonFilled">Register</button>
              </div>
            </div>
            <div>
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="privacy-policy-icon04 socialIcons"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="privacy-policy-icon06 socialIcons"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="privacy-policy-icon08 socialIcons"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div>
      <div className="privacy-policy-hero">
        <div className="heroContainer privacy-policy-hero1">
          <div className="privacy-policy-container01">
            <h1 className="privacy-policy-hero-heading heading1">
              Connect, Share, and Build
            </h1>
            <span className="privacy-policy-hero-sub-heading bodyLarge">
              <span>
                <span>
                  <span>Empowering women to forge meaningful connections</span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </span>
            </span>
            <div className="privacy-policy-btn-group">
              <button className="buttonFilled">Join Now</button>
              <button className="buttonFlat">Learn More →</button>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-policy-gallery">
        <div className="privacy-policy-gallery1">
          <div className="privacy-policy-container02">
            <div className="privacy-policy-container03"></div>
          </div>
          <div className="privacy-policy-container04">
            <div className="privacy-policy-container05"></div>
          </div>
          <div className="privacy-policy-container06"></div>
          <div className="privacy-policy-container07"></div>
          <div className="privacy-policy-container08"></div>
          <div className="privacy-policy-container09"></div>
        </div>
      </div>
      <div className="privacy-policy-features">
        <div className="featuresContainer">
          <div className="privacy-policy-features1">
            <div className="privacy-policy-container10">
              <h2 className="privacy-policy-features-heading">
                <span className="heading2">Privacy Policy</span>
                <br></br>
                <br></br>
              </h2>
              <span className="privacy-policy-features-sub-heading bodyLarge">
                <span>
                  <span>
                    <span>
                      Discover the key features that make our platform the
                      perfect place for women to connect and build friendships.
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <span>
              <span>Privacy Policy for Meet Women Online</span>
              <br></br>
              <br></br>
              <span>Effective date: March 26, 2024</span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>
                Welcome to Meet Women Online (the &quot;Website&quot;), operated
                by [Your Company Name] (&quot;us&quot;, &quot;we&quot;, or
                &quot;our&quot;). Your privacy is critically important to us,
                and this policy aims to explain how we collect, use, and
                safeguard the personal information you provide when you use our
                Website.
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>1. Information Collection and Use</span>
              <br></br>
              <br></br>
              <span>
                We collect several different types of information for various
                purposes to provide and improve our service to you.
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>Types of Data Collected</span>
              <br></br>
              <br></br>
              <span>
                Personal Data: While using our Website, we may ask you to
                provide us with certain personally identifiable information that
                can be used to contact or identify you (&quot;Personal
                Data&quot;). Personally identifiable information may include,
                but is not limited to: Email address, First name and last name,
                Phone number, Address, State, Province, ZIP/Postal code, City,
                and Cookies and Usage Data.
              </span>
              <br></br>
              <br></br>
              <span>
                Usage Data: We may also collect information on how the Website
                is accessed and used (&quot;Usage Data&quot;). This Usage Data
                may include information such as your computer&apos;s Internet
                Protocol address (e.g., IP address), browser type, browser
                version, our Website pages that you visit, the time and date of
                your visit, the time spent on those pages, unique device
                identifiers, and other diagnostic data.
              </span>
              <br></br>
              <br></br>
              <span>2. Use of Data</span>
              <br></br>
              <br></br>
              <span>
                Meet Women Online uses the collected data for various purposes:
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>To provide and maintain our Website</span>
              <br></br>
              <br></br>
              <span>To notify you about changes to our Website</span>
              <br></br>
              <br></br>
              <span>
                To allow you to participate in interactive features of our
                Website when you choose to do so
              </span>
              <br></br>
              <br></br>
              <span>To provide customer support</span>
              <br></br>
              <br></br>
              <span>
                To gather analysis or valuable information so that we can
                improve our Website
              </span>
              <br></br>
              <br></br>
              <span>To monitor the usage of our Website</span>
              <br></br>
              <br></br>
              <span>To detect, prevent, and address technical issues</span>
              <br></br>
              <br></br>
              <span>3. Data Transfer</span>
              <br></br>
              <br></br>
              <span>
                Your information, including Personal Data, may be transferred to
                — and maintained on — computers located outside of your state,
                province, country, or other governmental jurisdiction where the
                data protection laws may differ from those of your jurisdiction.
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>
                If you are located outside [Your Country] and choose to provide
                information to us, please note that we transfer the data,
                including Personal Data, to [Your Country] and process it there.
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>4. Data Disclosure</span>
              <br></br>
              <br></br>
              <span>
                We may disclose your Personal Data in the good faith belief that
                such action is necessary to:
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>To comply with a legal obligation</span>
              <br></br>
              <br></br>
              <span>
                To protect and defend the rights or property of [Your Company
                Name]
              </span>
              <br></br>
              <br></br>
              <span>
                To prevent or investigate possible wrongdoing in connection with
                the Website
              </span>
              <br></br>
              <br></br>
              <span>
                To protect the personal safety of users of the Website or the
                public
              </span>
              <br></br>
              <br></br>
              <span>To protect against legal liability</span>
            </span>
            <div className="privacy-policy-container11"></div>
          </div>
        </div>
      </div>
      <div className="privacy-policy-banner">
        <div className="bannerContainer privacy-policy-banner1">
          <h1 className="privacy-policy-banner-heading heading2">
            Connect with like-minded women
          </h1>
          <span className="privacy-policy-banner-sub-heading bodySmall">
            <span>
              <span>
                <span>
                  Join our platform to connect with women who share similar
                  experiences, interests, and goals. Build meaningful
                  friendships and find the support you need in a welcoming
                  community.
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
          </span>
          <button className="buttonFilled">Join now</button>
        </div>
      </div>
      <div className="privacy-policy-faq">
        <div className="faqContainer">
          <div className="privacy-policy-faq1">
            <div className="privacy-policy-container12">
              <span className="overline">
                <span>FAQ</span>
                <br></br>
              </span>
              <h2 className="privacy-policy-text153 heading2">
                Common questions
              </h2>
              <span className="privacy-policy-text154 bodyLarge">
                <span>
                  Here are some of the most common questions that we get.
                </span>
                <br></br>
              </span>
            </div>
            <div className="privacy-policy-container13">
              <Question12
                answer="You can join the platform by signing up with your email address and creating a profile."
                question="How can I join the platform?"
              ></Question12>
              <Question12
                answer="Yes, the platform is exclusively for women to connect and build friendships."
                question="Is the platform only for women?"
              ></Question12>
              <Question12
                answer="Yes, you can share your experiences and connect with other women who may relate to you."
                question="Can I share personal experiences on the platform?"
              ></Question12>
              <Question12
                answer="You can find friends by browsing profiles, joining groups, and participating in discussions."
                question="How can I find friends on the platform?"
              ></Question12>
              <Question12
                answer="No, joining the platform and connecting with other women is completely free of charge."
                question="Is there a fee to join the platform?"
              ></Question12>
            </div>
          </div>
        </div>
      </div>
      <footer className="footerContainer privacy-policy-footer">
        <div className="privacy-policy-container14">
          <span className="logo">FRIENDS</span>
          <nav className="privacy-policy-nav1">
            <span className="bodySmall">About</span>
            <span className="privacy-policy-nav222 bodySmall">Events</span>
            <span className="privacy-policy-nav322 bodySmall">Blog</span>
            <span className="privacy-policy-nav422 bodySmall">Contact</span>
          </nav>
        </div>
        <div className="privacy-policy-separator"></div>
        <div className="privacy-policy-container15">
          <span className="bodySmall privacy-policy-text157">
            © 2023 myCompany, All Rights Reserved.
          </span>
          <div className="privacy-policy-icon-group1">
            <svg
              viewBox="0 0 950.8571428571428 1024"
              className="privacy-policy-icon10 socialIcons"
            >
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="privacy-policy-icon12 socialIcons"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg
              viewBox="0 0 602.2582857142856 1024"
              className="privacy-policy-icon14 socialIcons"
            >
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default PrivacyPolicy
