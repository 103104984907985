import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Question14 from '../components/question14'
import './terms-of-use.css'

const TermsOfUse = (props) => {
  return (
    <div className="terms-of-use-container">
      <Helmet>
        <title>Terms-of-Use - Meet Women Online</title>
        <meta name="description" content="Meet Slavic Women" />
        <meta property="og:title" content="Terms-of-Use - Meet Women Online" />
        <meta property="og:description" content="Meet Slavic Women" />
      </Helmet>
      <div className="terms-of-use-header">
        <header
          data-thq="thq-navbar"
          className="navbarContainer terms-of-use-navbar-interactive"
        >
          <span className="logo">FRIENDS</span>
          <div data-thq="thq-navbar-nav" className="terms-of-use-desktop-menu">
            <nav className="terms-of-use-links">
              <Link to="/terms-and-conditions" className="terms-of-use-nav12">
                <span className="bodySmall">Terms and Conditions</span>
                <br></br>
              </Link>
              <span className="terms-of-use-nav22">
                <span className="bodySmall">Privacy Policy</span>
                <br></br>
              </span>
              <span className="terms-of-use-nav32">
                <span className="bodySmall">Cookie Policy</span>
                <br></br>
              </span>
              <span className="terms-of-use-nav42 bodySmall">Terms of Use</span>
            </nav>
            <div className="terms-of-use-buttons">
              <button className="terms-of-use-login buttonFlat">Login</button>
              <button className="buttonFilled">Register</button>
            </div>
          </div>
          <div data-thq="thq-burger-menu" className="terms-of-use-burger-menu">
            <svg
              viewBox="0 0 1024 1024"
              className="terms-of-use-icon socialIcons"
            >
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
            data-thq="thq-mobile-menu"
            className="terms-of-use-mobile-menu1 mobileMenu"
          >
            <div className="terms-of-use-nav">
              <div className="terms-of-use-top">
                <span className="logo">FRIENDS</span>
                <div
                  data-thq="thq-close-menu"
                  className="terms-of-use-close-menu"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="terms-of-use-icon02 socialIcons"
                  >
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="terms-of-use-links1">
                <span className="terms-of-use-nav121 bodySmall">About</span>
                <span className="terms-of-use-nav221 bodySmall">Events</span>
                <span className="terms-of-use-nav321 bodySmall">Blog</span>
                <span className="terms-of-use-nav421 bodySmall">Contact</span>
              </nav>
              <div className="terms-of-use-buttons1">
                <button className="buttonFlat">Login</button>
                <button className="buttonFilled">Register</button>
              </div>
            </div>
            <div>
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="terms-of-use-icon04 socialIcons"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="terms-of-use-icon06 socialIcons"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="terms-of-use-icon08 socialIcons"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div>
      <div className="terms-of-use-hero">
        <div className="heroContainer terms-of-use-hero1">
          <div className="terms-of-use-container01">
            <h1 className="terms-of-use-hero-heading heading1">
              Connect, Share, and Build with Us
            </h1>
            <span className="terms-of-use-hero-sub-heading bodyLarge">
              <span>
                <span>
                  <span>
                    Join our platform to find support, make friends, and engage
                    in meaningful experiences
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </span>
            </span>
            <div className="terms-of-use-btn-group">
              <button className="buttonFilled">Join Now</button>
              <button className="buttonFlat">Learn More →</button>
            </div>
          </div>
        </div>
      </div>
      <div className="terms-of-use-gallery">
        <div className="terms-of-use-gallery1">
          <div className="terms-of-use-container02">
            <div className="terms-of-use-container03"></div>
          </div>
          <div className="terms-of-use-container04">
            <div className="terms-of-use-container05"></div>
          </div>
          <div className="terms-of-use-container06"></div>
          <div className="terms-of-use-container07"></div>
          <div className="terms-of-use-container08"></div>
          <div className="terms-of-use-container09"></div>
        </div>
      </div>
      <div className="terms-of-use-features">
        <div className="featuresContainer">
          <div className="terms-of-use-features1">
            <div className="terms-of-use-container10">
              <h2 className="terms-of-use-features-heading">
                <span className="heading2">Terms of Use</span>
                <br></br>
                <br></br>
              </h2>
              <span>
                <span>Terms of Use for Meet Women Online</span>
                <br></br>
                <br></br>
                <span>Last Updated: March 26, 2024</span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>Introduction</span>
                <br></br>
                <br></br>
                <span>
                  Welcome to Meet Women Online! Our services are provided to you
                  subject to the following terms and conditions. By accessing or
                  using the website located at [Insert Your Website URL], you
                  agree to be bound by these Terms of Use (&quot;Terms&quot;).
                  If you do not agree with any part of the terms, then you do
                  not have permission to access the website.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>Eligibility</span>
                <br></br>
                <br></br>
                <span>
                  By using Meet Women Online, you represent and warrant that:
                </span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>You are 18 years of age or older.</span>
                <br></br>
                <br></br>
                <span>
                  You have the legal capacity and agree to comply with these
                  Terms.
                </span>
                <br></br>
                <br></br>
                <span>
                  Your use of the website does not violate any applicable law or
                  regulation.
                </span>
                <br></br>
                <br></br>
                <span>User Accounts</span>
                <br></br>
                <br></br>
                <span>
                  To access certain features of the website, you may be required
                  to create an account. You agree to provide accurate, current,
                  and complete information during the registration process and
                  to update such information to keep it accurate, current, and
                  complete. We reserve the right to suspend or terminate your
                  account if any information provided during the registration
                  process or thereafter proves to be inaccurate, not current, or
                  incomplete.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>User Conduct</span>
                <br></br>
                <br></br>
                <span>
                  You are responsible for all your activities in connection with
                  the Services. Any fraudulent, abusive, or otherwise illegal
                  activity may be grounds for termination of your right to
                  access or use the Services. You may not post or transmit, or
                  cause to be posted or transmitted, any communication or
                  solicitation designed or intended to obtain password, account,
                  or private information from any Meet Women Online user.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>Content Ownership and Copyright Policy</span>
                <br></br>
                <br></br>
                <span>
                  Your Content: You retain all your ownership rights to your
                  content. By submitting content to Meet Women Online, you grant
                  us a worldwide, non-exclusive, royalty-free, sublicensable,
                  and transferable license to use, reproduce, distribute,
                  prepare derivative works of, display, and perform the content
                  in connection with the service.
                </span>
                <br></br>
                <br></br>
                <span>
                  Our Content: All content included on the website, such as
                  text, graphics, logos, images, as well as the compilation
                  thereof, and any software used on the site, is the property of
                  Meet Women Online or its suppliers and protected by copyright
                  and intellectual property laws.
                </span>
                <br></br>
                <br></br>
                <span>Links To Other Web Sites</span>
                <br></br>
                <br></br>
                <span>
                  Our Service may contain links to third-party web sites or
                  services that are not owned or controlled by Meet Women
                  Online. We have no control over, and assume no responsibility
                  for, the content, privacy policies, or practices of any third
                  party web sites or services. You further acknowledge and agree
                  that Meet Women Online shall not be responsible or liable,
                  directly or indirectly, for any damage or loss caused or
                  alleged to be caused by or in connection with use of or
                  reliance on any such content, goods or services available on
                  or through any such web sites or services.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>Disclaimer of Warranties; Limitation of Liability</span>
                <br></br>
                <br></br>
                <span>
                  We do not guarantee, represent or warrant that your use of our
                  service will be uninterrupted, timely, secure or error-free.
                  We do not warrant that the results that may be obtained from
                  the use of the service will be accurate or reliable.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>Changes</span>
                <br></br>
                <br></br>
                <span>
                  We reserve the right, at our sole discretion, to modify or
                  replace these Terms at any time. What constitutes a material
                  change will be determined at our sole discretion.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>Contact Us</span>
                <br></br>
                <br></br>
                <span>
                  If you have any questions about these Terms, please contact us
                  at [Insert Contact Information].
                </span>
              </span>
              <span className="terms-of-use-features-sub-heading bodyLarge">
                <span>
                  <span>
                    <span>
                      Explore the key features that make our platform a vibrant
                      community for women.
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="terms-of-use-container11"></div>
          </div>
        </div>
      </div>
      <div className="terms-of-use-banner">
        <div className="bannerContainer terms-of-use-banner1">
          <h1 className="terms-of-use-banner-heading heading2">
            Empowering Women, Building Connections
          </h1>
          <span className="terms-of-use-banner-sub-heading bodySmall">
            <span>
              <span>
                <span>
                  Welcome to our platform dedicated to fostering meaningful
                  connections among women. Join us to share experiences, find
                  support, make lasting friendships, and engage in exciting
                  events and enriching blog content. Together, let&apos;s create
                  a supportive community where every woman&apos;s voice is heard
                  and valued.
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
          </span>
          <button className="buttonFilled">Join Now</button>
        </div>
      </div>
      <div className="terms-of-use-faq">
        <div className="faqContainer">
          <div className="terms-of-use-faq1">
            <div className="terms-of-use-container12">
              <span className="overline">
                <span>FAQ</span>
                <br></br>
              </span>
              <h2 className="terms-of-use-text141 heading2">
                Common questions
              </h2>
              <span className="terms-of-use-text142 bodyLarge">
                <span>
                  Here are some of the most common questions that we get.
                </span>
                <br></br>
              </span>
            </div>
            <div className="terms-of-use-container13">
              <Question14
                answer="You can join the platform by signing up with your email and creating a profile."
                question="How can I join the platform?"
              ></Question14>
              <Question14
                answer="Yes, the platform is exclusively for women to connect and build friendships."
                question="Is the platform only for women?"
              ></Question14>
              <Question14
                answer="Absolutely! The platform encourages sharing experiences to support and connect with other women."
                question="Can I share my personal experiences on the platform?"
              ></Question14>
              <Question14
                answer="No, joining and using the platform is completely free for all members."
                question="Are there any fees for joining or using the platform?"
              ></Question14>
              <Question14
                answer="Once you join the platform, you will have access to event listings and blog posts where you can participate and engage with other members."
                question="How can I participate in events and engage with blog content?"
              ></Question14>
            </div>
          </div>
        </div>
      </div>
      <footer className="footerContainer terms-of-use-footer">
        <div className="terms-of-use-container14">
          <span className="logo">FRIENDS</span>
          <nav className="terms-of-use-nav1">
            <span className="bodySmall">About</span>
            <span className="terms-of-use-nav222 bodySmall">Events</span>
            <span className="terms-of-use-nav322 bodySmall">Blog</span>
            <span className="terms-of-use-nav422 bodySmall">Contact</span>
          </nav>
        </div>
        <div className="terms-of-use-separator"></div>
        <div className="terms-of-use-container15">
          <span className="bodySmall terms-of-use-text145">
            © 2023 myCompany, All Rights Reserved.
          </span>
          <div className="terms-of-use-icon-group1">
            <svg
              viewBox="0 0 950.8571428571428 1024"
              className="terms-of-use-icon10 socialIcons"
            >
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="terms-of-use-icon12 socialIcons"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg
              viewBox="0 0 602.2582857142856 1024"
              className="terms-of-use-icon14 socialIcons"
            >
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default TermsOfUse
