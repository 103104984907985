import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Question13 from '../components/question13'
import './cookie-policy.css'

const CookiePolicy = (props) => {
  return (
    <div className="cookie-policy-container">
      <Helmet>
        <title>Cookie-Policy - Meet Women Online</title>
        <meta name="description" content="Meet Slavic Women" />
        <meta property="og:title" content="Cookie-Policy - Meet Women Online" />
        <meta property="og:description" content="Meet Slavic Women" />
      </Helmet>
      <div className="cookie-policy-header">
        <header
          data-thq="thq-navbar"
          className="navbarContainer cookie-policy-navbar-interactive"
        >
          <span className="logo">FRIENDS</span>
          <div data-thq="thq-navbar-nav" className="cookie-policy-desktop-menu">
            <nav className="cookie-policy-links">
              <Link to="/terms-and-conditions" className="cookie-policy-nav12">
                <span className="bodySmall">Terms and Conditions</span>
                <br></br>
              </Link>
              <span className="cookie-policy-nav22">
                <span className="bodySmall">Privacy Policy</span>
                <br></br>
              </span>
              <span className="cookie-policy-nav32">
                <span className="bodySmall">Cookie Policy</span>
                <br></br>
              </span>
              <span className="cookie-policy-nav42 bodySmall">
                Terms of Use
              </span>
            </nav>
            <div className="cookie-policy-buttons">
              <button className="cookie-policy-login buttonFlat">Login</button>
              <button className="buttonFilled">Register</button>
            </div>
          </div>
          <div data-thq="thq-burger-menu" className="cookie-policy-burger-menu">
            <svg
              viewBox="0 0 1024 1024"
              className="cookie-policy-icon socialIcons"
            >
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
            data-thq="thq-mobile-menu"
            className="cookie-policy-mobile-menu1 mobileMenu"
          >
            <div className="cookie-policy-nav">
              <div className="cookie-policy-top">
                <span className="logo">FRIENDS</span>
                <div
                  data-thq="thq-close-menu"
                  className="cookie-policy-close-menu"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="cookie-policy-icon02 socialIcons"
                  >
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="cookie-policy-links1">
                <span className="cookie-policy-nav121 bodySmall">About</span>
                <span className="cookie-policy-nav221 bodySmall">Events</span>
                <span className="cookie-policy-nav321 bodySmall">Blog</span>
                <span className="cookie-policy-nav421 bodySmall">Contact</span>
              </nav>
              <div className="cookie-policy-buttons1">
                <button className="buttonFlat">Login</button>
                <button className="buttonFilled">Register</button>
              </div>
            </div>
            <div>
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="cookie-policy-icon04 socialIcons"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="cookie-policy-icon06 socialIcons"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="cookie-policy-icon08 socialIcons"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div>
      <div className="cookie-policy-hero">
        <div className="heroContainer cookie-policy-hero1">
          <div className="cookie-policy-container01">
            <h1 className="cookie-policy-hero-heading heading1">
              Connect with like-minded women
            </h1>
            <span className="cookie-policy-hero-sub-heading bodyLarge">
              <span>
                <span>
                  <span>
                    Join our community to find friendship, support, and
                    meaningful connections
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </span>
            </span>
            <div className="cookie-policy-btn-group">
              <button className="buttonFilled">Join Now</button>
              <button className="buttonFlat">Learn More →</button>
            </div>
          </div>
        </div>
      </div>
      <div className="cookie-policy-gallery">
        <div className="cookie-policy-gallery1">
          <div className="cookie-policy-container02">
            <div className="cookie-policy-container03"></div>
          </div>
          <div className="cookie-policy-container04">
            <div className="cookie-policy-container05"></div>
          </div>
          <div className="cookie-policy-container06"></div>
          <div className="cookie-policy-container07"></div>
          <div className="cookie-policy-container08"></div>
          <div className="cookie-policy-container09"></div>
        </div>
      </div>
      <div className="cookie-policy-features">
        <div className="featuresContainer">
          <div className="cookie-policy-features1">
            <div className="cookie-policy-container10">
              <h2 className="cookie-policy-features-heading">
                <span className="heading2">Cookie Policy</span>
                <br></br>
              </h2>
              <span className="cookie-policy-features-sub-heading bodyLarge">
                <span>
                  <span>
                    <span>
                      Discover the key features of our platform that empower
                      women to connect, share, and build lasting friendships.
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="cookie-policy-container11"></div>
            <span>
              <span>Cookie Policy for Meet Women Online</span>
              <br></br>
              <br></br>
              <span>Last updated: March 26, 2024</span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>
                At Meet Women Online, accessible from our website, one of our
                main priorities is the privacy of our visitors. This Cookie
                Policy document contains types of information that is collected
                and recorded by Meet Women Online and how we use it.
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>
                If you have additional questions or require more information
                about our Cookie Policy, do not hesitate to contact us.
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>What Are Cookies</span>
              <br></br>
              <br></br>
              <span>
                As is common practice with almost all professional websites, our
                site uses cookies, which are tiny files that are downloaded to
                your computer, to improve your experience. This page describes
                what information they gather, how we use it, and why we
                sometimes need to store these cookies. We will also share how
                you can prevent these cookies from being stored; however, this
                may downgrade or &apos;break&apos; certain elements of the
                site&apos;s functionality.
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>How We Use Cookies</span>
              <br></br>
              <br></br>
              <span>
                We use cookies for a variety of reasons detailed below.
                Unfortunately, in most cases, there are no industry standard
                options for disabling cookies without completely disabling the
                functionality and features they add to this site. It is
                recommended that you leave on all cookies if you are not sure
                whether you need them or not in case they are used to provide a
                service that you use.
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>Disabling Cookies</span>
              <br></br>
              <br></br>
              <span>
                You can prevent the setting of cookies by adjusting the settings
                on your browser (see your browser Help for how to do this). Be
                aware that disabling cookies will affect the functionality of
                this and many other websites that you visit. Disabling cookies
                will usually result in also disabling certain functionality and
                features of this site. Therefore, it is recommended that you do
                not disable cookies.
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>The Cookies We Set</span>
              <br></br>
              <br></br>
              <span>
                Account related cookies: If you create an account with us, we
                will use cookies for the management of the signup process and
                general administration. These cookies will usually be deleted
                when you log out, but in some cases, they may remain afterward
                to remember your site preferences when logged out.
              </span>
              <br></br>
              <br></br>
              <span>
                Login related cookies: We use cookies when you are logged in so
                that we can remember this fact. This prevents you from having to
                log in every single time you visit a new page. These cookies are
                typically removed or cleared when you log out to ensure that you
                can only access restricted features and areas when logged in.
              </span>
              <br></br>
              <br></br>
              <span>
                Email newsletters related cookies: This site offers newsletter
                or email subscription services, and cookies may be used to
                remember if you are already registered and whether to show
                certain notifications which might only be valid to
                subscribed/unsubscribed users.
              </span>
              <br></br>
              <br></br>
              <span>
                Orders processing related cookies: This site offers e-commerce
                or payment facilities, and some cookies are essential to ensure
                that your order is remembered between pages so that we can
                process it properly.
              </span>
              <br></br>
              <br></br>
              <span>
                Surveys related cookies: From time to time, we offer user
                surveys and questionnaires to provide you with interesting
                insights, helpful tools, or to understand our user base more
                accurately. These surveys may use cookies to remember who has
                already taken part in a survey or to provide you with accurate
                results after you change pages.
              </span>
              <br></br>
              <br></br>
              <span>
                Forms related cookies: When you submit data through a form such
                as those found on contact pages or comment forms, cookies may be
                set to remember your user details for future correspondence.
              </span>
              <br></br>
              <br></br>
              <span>
                Site preferences cookies: In order to provide you with a great
                experience on this site, we provide the functionality to set
                your preferences for how this site runs when you use it. In
                order to remember your preferences, we need to set cookies so
                that this information can be called whenever you interact with a
                page is affected by your preferences.
              </span>
              <br></br>
              <br></br>
              <span>Third Party Cookies</span>
              <br></br>
              <br></br>
              <span>
                In some special cases, we also use cookies provided by trusted
                third parties. The following section details which third party
                cookies you might encounter through this site:
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>
                Analytics cookies: This site uses Google Analytics, which is one
                of the most widespread and trusted analytics solutions on the
                web for helping us to understand how you use the site and ways
                that we can improve your experience. These cookies may track
                things such as how long you spend on the site and the pages that
                you visit, so we can continue to produce engaging content.
              </span>
              <br></br>
              <br></br>
              <span>
                Advertising cookies: These cookies are used to make advertising
                messages more relevant to you. They perform functions like
                preventing the same ad from continuously reappearing, ensuring
                that ads are properly displayed for advertisers, and in some
                cases selecting advertisements that are based on your interests.
              </span>
              <br></br>
              <br></br>
              <span>More Information</span>
              <br></br>
              <br></br>
              <span>
                Hopefully, that has clarified things for you. As was previously
                mentioned, if there is something that you aren&apos;t sure
                whether you need or not, it&apos;s usually safer to leave
                cookies enabled in case it does interact with one of the
                features you use on our site.
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>
                However, if you are still looking for more information, you can
                contact us through one of our preferred contact methods:
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span>Email: [insert your contact email]</span>
              <br></br>
              <br></br>
              <span>By visiting this link: [insert your contact page URL]</span>
              <br></br>
              <br></br>
              <span>This policy is effective as of March 26, 2024.</span>
            </span>
          </div>
        </div>
      </div>
      <div className="cookie-policy-banner">
        <div className="bannerContainer cookie-policy-banner1">
          <h1 className="cookie-policy-banner-heading heading2">
            Empowering Women Through Connection
          </h1>
          <span className="cookie-policy-banner-sub-heading bodySmall">
            <span>
              <span>
                <span>
                  Welcome to our platform dedicated to fostering meaningful
                  connections among women. Join us to share experiences, offer
                  support, and build lasting friendships in a supportive
                  community. Together, we can create a space where every woman
                  feels heard, valued, and empowered. Let&apos;s connect and
                  grow together!
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
          </span>
          <button className="buttonFilled">Join Now</button>
        </div>
      </div>
      <div className="cookie-policy-faq">
        <div className="faqContainer">
          <div className="cookie-policy-faq1">
            <div className="cookie-policy-container12">
              <span className="overline">
                <span>FAQ</span>
                <br></br>
              </span>
              <h2 className="cookie-policy-text154 heading2">
                Common questions
              </h2>
              <span className="cookie-policy-text155 bodyLarge">
                <span>
                  Here are some of the most common questions that we get.
                </span>
                <br></br>
              </span>
            </div>
            <div className="cookie-policy-container13">
              <Question13
                answer="You can join the platform by signing up with your email address and creating a profile."
                question="How can I join the platform?"
              ></Question13>
              <Question13
                answer="Yes, the platform is exclusively for women to connect and build friendships."
                question="Is the platform only for women?"
              ></Question13>
              <Question13
                answer="Yes, you can share your experiences and connect with other women who may relate to your journey."
                question="Can I share personal experiences on the platform?"
              ></Question13>
              <Question13
                answer="You can find friends by browsing profiles, joining group discussions, and attending virtual events."
                question="How can I find friends on the platform?"
              ></Question13>
              <Question13
                answer="Yes, our community is built on support, inclusivity, and respect for all members."
                question="Is the community supportive and inclusive?"
              ></Question13>
            </div>
          </div>
        </div>
      </div>
      <footer className="footerContainer cookie-policy-footer">
        <div className="cookie-policy-container14">
          <span className="logo">FRIENDS</span>
          <nav className="cookie-policy-nav1">
            <span className="bodySmall">About</span>
            <span className="cookie-policy-nav222 bodySmall">Events</span>
            <span className="cookie-policy-nav322 bodySmall">Blog</span>
            <span className="cookie-policy-nav422 bodySmall">Contact</span>
          </nav>
        </div>
        <div className="cookie-policy-separator"></div>
        <div className="cookie-policy-container15">
          <span className="bodySmall cookie-policy-text158">
            © 2023 myCompany, All Rights Reserved.
          </span>
          <div className="cookie-policy-icon-group1">
            <svg
              viewBox="0 0 950.8571428571428 1024"
              className="cookie-policy-icon10 socialIcons"
            >
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="cookie-policy-icon12 socialIcons"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg
              viewBox="0 0 602.2582857142856 1024"
              className="cookie-policy-icon14 socialIcons"
            >
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default CookiePolicy
