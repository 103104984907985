import React from 'react'

import { Helmet } from 'react-helmet'

import Question11 from '../components/question11'
import './terms-and-conditions.css'

const TermsAndConditions = (props) => {
  return (
    <div className="terms-and-conditions-container">
      <Helmet>
        <title>Terms-and-Conditions - Meet Women Online</title>
        <meta name="description" content="Meet Slavic Women" />
        <meta
          property="og:title"
          content="Terms-and-Conditions - Meet Women Online"
        />
        <meta property="og:description" content="Meet Slavic Women" />
      </Helmet>
      <div className="terms-and-conditions-header">
        <header
          data-thq="thq-navbar"
          className="navbarContainer terms-and-conditions-navbar-interactive"
        >
          <span className="logo">FRIENDS</span>
          <div
            data-thq="thq-navbar-nav"
            className="terms-and-conditions-desktop-menu"
          >
            <nav className="terms-and-conditions-links">
              <span>
                <span className="bodySmall">Terms and Conditions</span>
                <br></br>
              </span>
              <span className="terms-and-conditions-nav22">
                <span className="bodySmall">Privacy Policy</span>
                <br></br>
              </span>
              <span className="terms-and-conditions-nav32">
                <span className="bodySmall">Cookie Policy</span>
                <br></br>
              </span>
              <span className="terms-and-conditions-nav42 bodySmall">
                Terms of Use
              </span>
            </nav>
            <div className="terms-and-conditions-buttons">
              <button className="terms-and-conditions-login buttonFlat">
                Login
              </button>
              <button className="buttonFilled">Register</button>
            </div>
          </div>
          <div
            data-thq="thq-burger-menu"
            className="terms-and-conditions-burger-menu"
          >
            <svg
              viewBox="0 0 1024 1024"
              className="terms-and-conditions-icon socialIcons"
            >
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
            data-thq="thq-mobile-menu"
            className="terms-and-conditions-mobile-menu1 mobileMenu"
          >
            <div className="terms-and-conditions-nav">
              <div className="terms-and-conditions-top">
                <span className="logo">FRIENDS</span>
                <div
                  data-thq="thq-close-menu"
                  className="terms-and-conditions-close-menu"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="terms-and-conditions-icon02 socialIcons"
                  >
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="terms-and-conditions-links1">
                <span className="terms-and-conditions-nav121 bodySmall">
                  About
                </span>
                <span className="terms-and-conditions-nav221 bodySmall">
                  Events
                </span>
                <span className="terms-and-conditions-nav321 bodySmall">
                  Blog
                </span>
                <span className="terms-and-conditions-nav421 bodySmall">
                  Contact
                </span>
              </nav>
              <div className="terms-and-conditions-buttons1">
                <button className="buttonFlat">Login</button>
                <button className="buttonFilled">Register</button>
              </div>
            </div>
            <div>
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="terms-and-conditions-icon04 socialIcons"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="terms-and-conditions-icon06 socialIcons"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="terms-and-conditions-icon08 socialIcons"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div>
      <div className="terms-and-conditions-hero">
        <div className="heroContainer terms-and-conditions-hero1">
          <div className="terms-and-conditions-container01">
            <h1 className="terms-and-conditions-hero-heading heading1">
              Connect, Share, and Build
            </h1>
            <span className="terms-and-conditions-hero-sub-heading bodyLarge">
              <span>
                <span>
                  <span>Empowering women to form meaningful connections</span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </span>
            </span>
            <div className="terms-and-conditions-btn-group">
              <button className="buttonFilled">Join Now</button>
              <button className="buttonFlat">Learn More →</button>
            </div>
          </div>
        </div>
      </div>
      <div className="terms-and-conditions-gallery">
        <div className="terms-and-conditions-gallery1">
          <div className="terms-and-conditions-container02">
            <div className="terms-and-conditions-container03"></div>
          </div>
          <div className="terms-and-conditions-container04">
            <div className="terms-and-conditions-container05"></div>
          </div>
          <div className="terms-and-conditions-container06"></div>
          <div className="terms-and-conditions-container07"></div>
          <div className="terms-and-conditions-container08"></div>
          <div className="terms-and-conditions-container09"></div>
        </div>
      </div>
      <div className="terms-and-conditions-features">
        <div className="featuresContainer">
          <div className="terms-and-conditions-features1">
            <div className="terms-and-conditions-container10">
              <h2 className="terms-and-conditions-features-heading">
                <span className="heading2">Terms and Conditions</span>
                <br></br>
                <br></br>
              </h2>
              <span>
                <span>Terms and Conditions for Meet Women Online</span>
                <br></br>
                <br></br>
                <span>Last updated: March 26, 2024</span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>Welcome to Meet Women Online!</span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>
                  These terms and conditions outline the rules and regulations
                  for the use of [Your Company Name]&apos;s Website, located at
                  [YourWebsite.com].
                </span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>
                  By accessing this website, we assume you accept these terms
                  and conditions. Do not continue to use Meet Women Online if
                  you do not agree to take all of the terms and conditions
                  stated on this page.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>
                  The following terminology applies to these Terms and
                  Conditions, Privacy Statement and Disclaimer Notice, and all
                  Agreements: &quot;Client&quot;, &quot;You&quot;, and
                  &quot;Your&quot; refers to you, the person log on this website
                  and compliant to the Company’s terms and conditions. &quot;The
                  Company&quot;, &quot;Ourselves&quot;, &quot;We&quot;,
                  &quot;Our&quot;, and &quot;Us&quot;, refers to our Company.
                  &quot;Party&quot;, &quot;Parties&quot;, or &quot;Us&quot;,
                  refers to both the Client and ourselves. All terms refer to
                  the offer, acceptance, and consideration of payment necessary
                  to undertake the process of our assistance to the Client in
                  the most appropriate manner for the express purpose of meeting
                  the Client’s needs in respect of provision of the Company’s
                  stated services, in accordance with and subject to, prevailing
                  law of Netherlands. Any use of the above terminology or other
                  words in the singular, plural, capitalization, and/or he/she
                  or they, are taken as interchangeable and therefore as
                  referring to same.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>Cookies</span>
                <br></br>
                <br></br>
                <span>
                  We employ the use of cookies. By accessing Meet Women Online,
                  you agreed to use cookies in agreement with the [Your Company
                  Name]&apos;s Privacy Policy.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>
                  Most interactive websites use cookies to let us retrieve the
                  user’s details for each visit. Cookies are used by our website
                  to enable the functionality of certain areas to make it easier
                  for people visiting our website. Some of our
                  affiliate/advertising partners may also use cookies.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>License</span>
                <br></br>
                <br></br>
                <span>
                  Unless otherwise stated, [Your Company Name] and/or its
                  licensors own the intellectual property rights for all
                  material on Meet Women Online. All intellectual property
                  rights are reserved. You may access this from Meet Women
                  Online for your own personal use subjected to restrictions set
                  in these terms and conditions.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>You must not:</span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <span>Republish material from Meet Women Online</span>
                <br></br>
                <br></br>
                <span>
                  Sell, rent, or sub-license material from Meet Women Online
                </span>
                <br></br>
                <br></br>
                <span>
                  Reproduce, duplicate or copy material from Meet Women Online
                </span>
                <br></br>
                <br></br>
                <span>Redistribute content from Meet Women Online</span>
              </span>
              <span className="terms-and-conditions-features-sub-heading bodyLarge">
                <span>
                  <span>
                    <span>
                      Discover the key features that make our platform a
                      supportive and enriching space for women.
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="terms-and-conditions-container11"></div>
          </div>
        </div>
      </div>
      <div className="terms-and-conditions-banner">
        <div className="bannerContainer terms-and-conditions-banner1">
          <h1 className="terms-and-conditions-banner-heading heading2">
            Empowering Women Through Connection
          </h1>
          <span className="terms-and-conditions-banner-sub-heading bodySmall">
            <span>
              <span>
                <span>
                  Welcome to our platform dedicated to fostering meaningful
                  connections among women. Join us to share experiences, find
                  support, and build lasting friendships. Together, we can
                  create a supportive community where every woman&apos;s voice
                  is heard and valued.
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
          </span>
          <button className="buttonFilled">Join Now</button>
        </div>
      </div>
      <div className="terms-and-conditions-faq">
        <div className="faqContainer">
          <div className="terms-and-conditions-faq1">
            <div className="terms-and-conditions-container12">
              <span className="overline">
                <span>FAQ</span>
                <br></br>
              </span>
              <h2 className="terms-and-conditions-text119 heading2">
                Common questions
              </h2>
              <span className="terms-and-conditions-text120 bodyLarge">
                <span>
                  Here are some of the most common questions that we get.
                </span>
                <br></br>
              </span>
            </div>
            <div className="terms-and-conditions-container13">
              <Question11
                answer="You can join the platform by signing up with your email address and creating a profile."
                question="How can I join the platform?"
              ></Question11>
              <Question11
                answer="Yes, the platform is exclusively for women to connect and build friendships."
                question="Is the platform only for women?"
              ></Question11>
              <Question11
                answer="Yes, you are encouraged to share your experiences and connect with other women who may relate."
                question="Can I share my personal experiences on the platform?"
              ></Question11>
              <Question11
                answer="You can find support by joining groups, participating in discussions, and reaching out to other members."
                question="How can I find support on the platform?"
              ></Question11>
              <Question11
                answer="No, the platform is free to use for all members."
                question="Are there any fees for using the platform?"
              ></Question11>
            </div>
          </div>
        </div>
      </div>
      <footer className="footerContainer terms-and-conditions-footer">
        <div className="terms-and-conditions-container14">
          <span className="logo">FRIENDS</span>
          <nav className="terms-and-conditions-nav1">
            <span className="bodySmall">About</span>
            <span className="terms-and-conditions-nav222 bodySmall">
              Events
            </span>
            <span className="terms-and-conditions-nav322 bodySmall">Blog</span>
            <span className="terms-and-conditions-nav422 bodySmall">
              Contact
            </span>
          </nav>
        </div>
        <div className="terms-and-conditions-separator"></div>
        <div className="terms-and-conditions-container15">
          <span className="bodySmall terms-and-conditions-text123">
            © 2023 myCompany, All Rights Reserved.
          </span>
          <div className="terms-and-conditions-icon-group1">
            <svg
              viewBox="0 0 950.8571428571428 1024"
              className="terms-and-conditions-icon10 socialIcons"
            >
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="terms-and-conditions-icon12 socialIcons"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg
              viewBox="0 0 602.2582857142856 1024"
              className="terms-and-conditions-icon14 socialIcons"
            >
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default TermsAndConditions
